<template>
  <div class="mt-2 px-0 md:px-5 flex justify-between h-auto items-center">
        <div class="block md:hidden">
            <slot name="left"></slot>
       </div>
        <label class="font-sans font-bold tracking-wide text-gray-500 text-base md:text-2xl w-full sm:text-center md:text-left">{{title ? title : ''}}</label>
       <div class="hidden md:block">
            <slot name="right"></slot>
       </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ""
        }
    }
}
</script>

<style>

</style>