<template>
        <div class="px-5 py-1 grid gap-y-2">
            <div class="flex gap-5 font-sans">
                <span class="font-bold text-gray-400 tracking-wide"><slot name="title"></slot></span>
                <span class="font-semibold tracking-wide"><slot name="text"></slot></span>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>