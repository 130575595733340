export function getSubtotal(price, taxes, has_taxes = false) {

    let response = price || 0;
    if(has_taxes) {
        let taxes_calculated = 0;
        for(let taxe of taxes) {
            taxes_calculated += (taxe.value / 100);
        }
        taxes_calculated += 1;
        for(let taxe of taxes) {
            let value_taxe = (response * (taxe.value / 100)) / (taxes_calculated);
            taxe.value_taxe = value_taxe;
        }
        for(let taxe of taxes) {
            response = response - taxe.value_taxe
        }
    }else {
         for(let taxe of taxes) {
            let value_taxe = (taxe.value / 100) * price;
            taxe.value_taxe = value_taxe;
        }
    }
    return response;

}


export function getTotal(taxes, subtotal) {
    const taxesSum = taxes.reduce((acc, val) =>  acc + isNaN(val.value_taxe) ? 0 : val.value_taxe, 0);
    return taxesSum+subtotal;
}