<template>
  <div class="py-2 bg-gray-100" style="min-height: 80%;">
        <v-loading :is_loading="isloading" />
         <v-card elevation="1" class="mt-2">
            <v-card-text class="grid gap-y-3">
               <div class="mt-2 px-5 flex justify-between items-center">
                  <label class="font-sans font-bold tracking-wide text-gray-500 text-xl">{{'Lista de Menú'}}</label>
                   <v-dialog
                    v-model="iscreate"
                    persistent
                    max-width="700px"
                    :retain-focus="false"
                    v-security="{acm: $route.meta.acm, acc: acc.ADD_PRODUCT}"
                    >
                    <template  v-slot:activator="{ on, attrs }" >
                    <v-btn
                        v-bind="attrs" v-on="on"
                        color="pink"
                        dark
                        fab
                        small
                        >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    </template>
                    <v-products-create :id_entity="identity" :id_category="id" :open="iscreate" v-on:close="iscreate = false" v-on:success="onsuccess" />
                    </v-dialog>
                </div>
                <v-divider></v-divider>
                 <div class="mt-5">
                    <v-data-table
                        item-key="id"
                        :headers="headers"
                        :items="products"
                        class="elevation-1"
                        hide-default-footer
                        :no-data-text="'No se encontraron resultados'"
                        :multi-sort="false"
                    >
                    <template v-slot:[`item.price`]="{item}">
                        {{item.price | currency}}
                    </template>
                    <template v-slot:[`item.active`]="{item}">
                         <v-switch
                            inset
                            v-model="item.active"
                            color="pink lighten-1"
                            @click="onClickActive(item)"
                            ></v-switch>
                    </template>
                    <template v-slot:[`item.actions`]="{item}">
                            <rb-options :actions="actions" v-on:event="(event) => onEvent(event, item)">
                                <template #buttonsheet>
                                    <rb-icon-settings class="block md:hidden" />
                                </template>
                                <template #buttonmenu>
                                    <rb-icon-settings class="hidden md:block" />
                                </template>
                            </rb-options>

                    </template>
                    </v-data-table>
                    <div class="text-center mt-3">
                    <v-pagination
                        color="pink lighten-1"
                        v-model="pagination.page"
                        :length="pagination.total"
                        :total-visible="5"
                        circle
                    ></v-pagination>
                    </div>
                </div>
            </v-card-text>
        </v-card>

            <v-dialog
            v-model="isupdate"
            persistent
            max-width="700px"
            :retain-focus="false"
            >
            <v-products-update :id="id_product_selected" :id_entity="identity" :id_category="id" :open="isupdate" v-on:close="isupdate = false" v-on:success="onupdate" />
            </v-dialog>
  </div>
</template>

<script>
import RBOptionSettings from '../../../@core/components/form/icon/RBOptionSettings.vue';
import ToastMixin from '@/mixins/vue/toastmixin';
import SecurityMixin from '@/mixins/security/RBSecurityMixin';
import globalMixin from '@/mixins/globalMixin';
import vproductscreate from '../menu/create.vue';
import vproductsupdate from '../menu/update.vue';
import { MenuService } from '../../../services/HSuite/Context/hsuite-product-context';
import actions from '../../../utils/common/actions';

export default {
    mixins: [globalMixin, ToastMixin, SecurityMixin],
    components: {
        'v-products-create' : vproductscreate,
        'v-products-update' : vproductsupdate,
        'rb-icon-settings' : RBOptionSettings
    },
    props: {
        id: {
            type: String,
            default: ""
        },
        identity: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            iscreate: false,
            isupdate: false,
            isloading: false,
            id_product_selected: null,
            category: {},
            taxes: [],
            products: [],
            headers: [
                {text: 'Nombre', value: 'name'},
                {text: 'Descripción', value: 'description'},
                {text: 'Precio', value: 'price'},
                {text: '¿Mostrar?', value: 'active'},
                {text: 'Acciones', value: 'actions',}
            ],
           
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init:async function() {
            this.isloading = true;
            await this.getProducts(this.id, this.identity, this.pagination.page, this.pagination.perpage);
            this.isloading = false;
        },
       getProducts: async function(id_category, id_entity, page, limit) {
            let response = await MenuService.getProductsByCategory(id_category, id_entity, page, limit);
            if(response.success) {
                this.products = response.data.data;
                this.pagination.total = response.data.total_page;
            }else {
                this.failure(response.message);
            }
        },
        onsuccess: function() {
            this.iscreate = false;
            this.init();
        },
        onupdate: function() {
            this.isupdate = false;
            this.init();
        },
        onClickActive: async function (item) {
            let action = item.active;
            let confirm = await this.confirm(action ? 'Mostrar' : 'Ocultar', '¿Desea ocultar/mostrar el producto en su categoría?');
            if(confirm) {
                let data = {active: item.active};
                this.isloading = true;
                let response = await MenuService.status(item.id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                     item.active = action ? false : true;
                }

            }else {
                item.active = action ? false : true;
            }
        },
        onEvent: function (event, item) {
            switch(event) {
                case actions.EDIT:
                    this.id_product_selected = item.id;
                    this.isupdate = true;
                break;
                case actions.SHOW:
                    this.$router.push(`/entity/menu/${item.id}/${this.identity}`)
                break;
            }
        },
        onPage: async function() {
          this.isloading = true;
        await this.getProducts(this.id, this.identity, this.pagination.page, this.pagination.perpage);
          this.isloading = false;
      },
    },
    computed: {
       
        page: function() {
          return this.pagination.page;
        },
        actions: function() {
           let acc = [
                {label: 'Visualizar', icon: 'eye', action: actions.SHOW,  description: 'Visualizar los datos del menú', acc: this.acc.SHOW_PRODUCT, acm: this.acm.MENU.name},
                {label: 'Actualizar', icon: 'edit', action: actions.EDIT,  description: 'Actualizar los datos del menú',  acc: this.acc.EDIT_PRODUCT, acm: this.acm.MENU.name},
                {label: 'Eliminar', icon: 'trash-alt', action: actions.DELETE,  description: 'Eliminar de forma permanente',  acc: this.acc.DELETE_PRODUCT, acm: this.acm.MENU.name},
            ]
            return acc;
        }
    },
    watch: {
        page: function() {
            this.onPage();
        },
    }

}
</script>

<style>

</style>