<template>
  <div class="px-5 py-2 bg-gray-100 min-screen-content">
       <v-loading :is_loading="isloading" />
       <rb-header-primary :title="'Información de Categoría'" >
            <template #left>
                <rb-button-back />
            </template>
            <template #right>
                <rb-button-back />
            </template>
       </rb-header-primary>
        <v-card elevation="1" class="mt-2">
            <div class="grid cols-1 md:grid-cols-12">
                    <div class="col-start-1 col-end-1 md:col-start-1 md:col-end-4 py-5 md:border-r border-gray-200">
                       <div class="flex justify-center items-center w-full h-full">
                           <div class="grid gap-y-3 w-full px-5 md:px-10">
                                <div class="w-full h-44 md:w-full md:h-44 bg-gray-100 rounded-md shadow-md bg-cover bg-center" :style="`background-image: url('${category.image}')`"></div>
                                <div class="text-center">
                                    <label class="font-sans font-bold tracking-wide text-gray-700 text-xl">{{category.name ? category.name : ''}}</label>
                                </div>
                           </div>
                       </div>
                    </div>
                    <div class="col-start-1 col-end-1 md:col-start-4 md:col-end-13 ">
                        <div class="text-gray-500 font-bold bg-gray-200 px-5 py-2 font-sans tracking-wide  border-b border-t flex justify-end align-middle">
                            <div>
                                <f-icon :icon="['fas', 'ellipsis-h']"></f-icon>
                            </div>
                        </div>

                        <rb-header-info :category="category" :taxes="taxes_active" />

                    </div>
            </div>
        </v-card>
        <div>
            <v-menu-table v-if="category.code_category_type == PRODUCT_CONTEXT.SYST_MENU" :id="id" :identity="identity" />
            <v-service-table v-if="category.code_category_type == PRODUCT_CONTEXT.SYST_SERVICE" :id="id" :identity="identity" />
        </div>

  </div>
</template>

<script>
import HeaderPrimary from '../../../@core/components/form/header/RBHeaderPrimary';
import ToastMixin from '@/mixins/vue/toastmixin';
import globalMixin from '@/mixins/globalMixin';
import categoryService from  '../../../services/entity/category.service';
import PRODUCT_CONTEXT from '../../../utils/common/productcontext';
import entityTaxService from '../../../services/entity/entitytax.service';
import vmenustable from '../menu/index.vue';
import vservicetable from '../services/index.vue';
import HeaderInfo from './components/RBCategoryHeaderInfo.vue';


export default {
    mixins: [ToastMixin, globalMixin],
    props: {
        id: {
            type: String,
            default: ""
        },
        identity: {
            type: String,
            default: ""
        }
    },
    components: {
        'rb-header-primary': HeaderPrimary,
        'v-menu-table' : vmenustable,
        'v-service-table' : vservicetable,
        'rb-header-info' : HeaderInfo
    },
    data: function() {
        return {
             isloading: false,
             category: {},
             taxes: [],
             PRODUCT_CONTEXT
        }
    },
    mounted() {
        this.init();
    },
    methods : {
        init:async function() {
            this.isloading = true;
            await this.getCategory(this.id, this.identity);
            await this.getEntityTaxs(this.identity);
            this.isloading = false;
        },
        getCategory: async function(id, id_entity) {
            let response = await categoryService.getById(id, id_entity);
            if(response.success) {
                this.category = response.data;
            }
        },
         getEntityTaxs: async function(id) {
            let response = await entityTaxService.getAll(id);
            if(response.success) {
                this.taxes = response.data;
            }else {
                this.failure(response.message);
            }
        },
    },
    computed: {
         taxes_active: function() {
            let taxes = this.taxes.filter(x => x.activo == 1);
            return taxes ? taxes : [];
        },
    }

}
</script>

<style>

</style>