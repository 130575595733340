<template>
  <div>
    <label class="text-base font-sans font-semibold">{{label ? label : ''}}</label>
    <slot name="input"></slot>
    <div class="flex justify-between">
        <div>         
            <small class="text-error" v-show="value.$anyDirty && !value.required">* El campo es requerido</small>
            <small class="text-error" v-show="value.$anyDirty && !value.minLength">* El campo debe contener un mínimo de {{value.$params.minLength.min}} caracteres</small>
            <small class="text-error" v-show="value.$anyDirty && !value.maxLength">* El campo debe contener un máximo de {{value.$params.maxLength.max}} caracteres</small>
        </div>
        <div>
            <small>{{ (value.$model ? value.$model.length : '0') +' / '+value.$params.maxLength.max }}</small>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            type: [Object],
            default: function(){ return {}}
        }
    }
}
</script>

<style>

</style>