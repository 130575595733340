var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2 bg-gray-100",staticStyle:{"min-height":"80%"}},[_c('v-loading',{attrs:{"is_loading":_vm.isloading}}),_c('v-card',{staticClass:"mt-2",attrs:{"elevation":"1"}},[_c('v-card-text',{staticClass:"grid gap-y-3"},[_c('div',{staticClass:"mt-2 px-5 flex justify-between items-center"},[_c('label',{staticClass:"font-sans font-bold tracking-wide text-gray-500 text-xl"},[_vm._v(_vm._s('Lista de Menú'))]),_c('v-dialog',{directives:[{name:"security",rawName:"v-security",value:({acm: _vm.$route.meta.acm, acc: _vm.acc.ADD_PRODUCT}),expression:"{acm: $route.meta.acm, acc: acc.ADD_PRODUCT}"}],attrs:{"persistent":"","max-width":"700px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"pink","dark":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.iscreate),callback:function ($$v) {_vm.iscreate=$$v},expression:"iscreate"}},[_c('v-products-create',{attrs:{"id_entity":_vm.identity,"id_category":_vm.id,"open":_vm.iscreate},on:{"close":function($event){_vm.iscreate = false},"success":_vm.onsuccess}})],1)],1),_c('v-divider'),_c('div',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.products,"hide-default-footer":"","no-data-text":'No se encontraron resultados',"multi-sort":false},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"pink lighten-1"},on:{"click":function($event){return _vm.onClickActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('rb-options',{attrs:{"actions":_vm.actions},on:{"event":function (event) { return _vm.onEvent(event, item); }},scopedSlots:_vm._u([{key:"buttonsheet",fn:function(){return [_c('rb-icon-settings',{staticClass:"block md:hidden"})]},proxy:true},{key:"buttonmenu",fn:function(){return [_c('rb-icon-settings',{staticClass:"hidden md:block"})]},proxy:true}],null,true)})]}}],null,true)}),_c('div',{staticClass:"text-center mt-3"},[_c('v-pagination',{attrs:{"color":"pink lighten-1","length":_vm.pagination.total,"total-visible":5,"circle":""},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"700px","retain-focus":false},model:{value:(_vm.isupdate),callback:function ($$v) {_vm.isupdate=$$v},expression:"isupdate"}},[_c('v-products-update',{attrs:{"id":_vm.id_product_selected,"id_entity":_vm.identity,"id_category":_vm.id,"open":_vm.isupdate},on:{"close":function($event){_vm.isupdate = false},"success":_vm.onupdate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }