<template>
    <v-card>
          <v-loading :is_loading="isloading" />
         <v-modal-title v-on:close="$emit('close')">
              Actualizar servicio
         </v-modal-title>
         <v-divider></v-divider>
           <v-skeleton v-if="loading" />
          <v-card-text v-if="service && !loading">
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                 <div>
                      <rb-input-group :label="'Nombre'" :value="$v.service.name">
                        <template #input>
                            <rb-input :placeholder="'Ingrese el nombre'" :value="service.name" v-model="$v.service.name.$model" text @input="v => service.name = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
                  <div>
                      <rb-input-group :label="'Precio'" :value="$v.service.price">
                        <template #input>
                            <rb-input :placeholder="'Ingrese el precio'" :value="service.price" v-model.number="$v.service.price.$model" number @input="v => service.price = v"></rb-input>
                        </template>
                      </rb-input-group>
                  </div>
             </div>
             <div class="grid grid-cols-1 px-1 py-1 gap-5">
                  <div>
                      <rb-texarea-group :label="'Descripción'" :value="$v.service.description">
                        <template #input>
                            <rb-textarea :placeholder="'Ingrese la descripción'" :value="service.description" v-model="$v.service.description.$model" text @input="v => service.description = v"></rb-textarea>
                        </template>
                      </rb-texarea-group>
                  </div>
             </div>
             <div class="grid grid-cols-1 px-1 py-1 gap-5" v-if="taxes_active && taxes_active.length > 0">
                  <div class="flex flex-wrap">
                      <v-sheet class="px-5">
                          <div class="flex items-center gap-5">
                              <v-switch
                              v-model="service.has_taxes"
                              color="success"
                              inset
                              :label="`${'¿Incluye impuestos?'}`"
                              ></v-switch>
                          </div>
                      </v-sheet>
                  </div>
             </div>
               <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                  <div>
                      <v-sheet class="px-5">
                          <div class="flex items-center gap-5">
                              <v-switch
                              v-for="(tax_item, i) in taxes_active"
                              :key="i"
                              v-model="tax_item.selected"
                              color="success"
                              inset
                              :label="`${tax_item.code}`"
                              ></v-switch>
                          </div>
                      </v-sheet>
                  </div>
                  <div >
                      <div class="flex justify-between items-center border-b border-gray-400">
                        <label class="text-base font-sans font-semibold">Subtotal </label>
                        <label class="text-base font-sans font-normal text-gray-500">{{subtotal | currency}} </label>
                      </div>
                       <div class="flex justify-between items-center border-b border-gray-400" v-for="(tax, i) in taxes_selected" :key="i">
                        <label class="text-base font-sans font-semibold">{{tax.code ? `${tax.code}(${tax.value}%)` : ''}}</label>
                        <label class="text-base font-sans font-normal text-gray-500">{{tax.value_taxe | currency }} </label>
                      </div>
                      <div class="flex justify-between items-center border-b border-gray-400">
                        <label class="text-base font-sans font-semibold">Total </label>
                        <label class="text-base font-sans font-normal text-gray-500">{{total | currency}} </label>
                      </div>
                  </div>
               </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
                  <rb-button :label="'Cerrar'" :css="'button-modal-gray'" modal small @click.native="$emit('close')" />
                  <rb-button :label="'Guardar'" :css="'button-modal-pink'" modal small @click.native="save" />
          </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import toastMixin from "@/mixins/vue/toastmixin";
  import Service from '../../../models/service/service.model';
  import { validationMixin } from 'vuelidate';
  import { required, minLength, maxLength } from 'vuelidate/lib/validators';
  import entityTaxService from '../../../services/entity/entitytax.service';
  import { ServicesService } from '../../../services/HSuite/Context/hsuite-product-context';
  import { getSubtotal, getTotal } from '../../../utils/product/produc.utilities';
  import RBInput from '../../../@core/components/form/input/RBInput.vue';
  import RBTextArea from '../../../@core/components/form/input/RBTextArea.vue';
  import RBInputPrimaryGroup from '../../../@core/components/form/group/RBInputPrimaryGroup.vue';
  import RBTextAreaPrimaryGroup from '../../../@core/components/form/group/RBTextAreaPrimaryGroup.vue';
  import RBPrimaryButton from '../../../@core/components/form/button/RBPrimaryButton.vue';
   
  export default {
       mixins: [validationMixin, toastMixin],
       props: {
          id: {
              type: String,
              default: ""
          },
          open: {
              type: Boolean,
              default: false
          },
          id_entity: {
              type: String,
              default: ""
          },
          id_category: {
              type: String,
              default: ""
          }
      },
      components: {
        'rb-input': RBInput,
        'rb-input-group': RBInputPrimaryGroup,
        'rb-textarea': RBTextArea,
        'rb-texarea-group': RBTextAreaPrimaryGroup,
        'rb-button': RBPrimaryButton,
      },
      data: function() {
          return {
              isloading: false,
              loading: false,
              service: new Service(),
              taxes: []
          }
      },
      
      validations: {
        service: {
            name: {
                 required
            },
            description: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(250)
            },
            price: {
                 required
            }
         }
      },
      mounted() {
          this.init();
      },
      methods: {
          init: async function() {
              if(this.id_entity != null && this.id_entity != '') {
                  this.loading = true;
                  await this.getEntityTaxs(this.id_entity);
                  await this.getService(this.id);
                  this.loading = false;
              }
          },
          getEntityTaxs: async function(id) {
              let response = await entityTaxService.getAll(id);
              if(response.success) {
                  this.taxes = response.data;
                  this.taxes = this.taxes.map((item => ({...item, selected: true})));
              }
          },
          getService: async function(id) {
              let response = await ServicesService.getById(id);
              if(!response.success) {this.failure(response.message); return; }
              this.service = response.data;
          },
          save: async function() {
              let taxes_str = this.taxes_selected.map((tax) => {
                  return tax.id_tax;
              }).join(',');
  
               this.service.taxes = taxes_str ? (taxes_str + ',') : '';
              this.$v.$touch();
              if(!this.$v.$anyError) {
                  this.isloading = true;
                  let response = await ServicesService.update(this.id,  this.service);
                  this.isloading = false;
                  if(response.success) {
                      this.success(response.message);
                      this.$emit('success');
                  }else {
                      this.failure(response.message);
                  }
              }
          }
  
      },
      computed: {
          taxes_active: function() {
              let taxes = this.taxes.filter(x => x.activo);
              return taxes ? taxes : []
          },
          taxes_selected: function() {
                let taxes = this.taxes_active.filter(x => x.selected);
                return taxes ? taxes : []
          },
          subtotal: function() {
              let response = getSubtotal( this.service.price, this.taxes_selected, this.service.has_taxes)
              return response
          },
          total: function() {
             const total = getTotal(this.taxes_selected, parseFloat(this.subtotal))
             return total;
          }
      },
      watch: {
           open: function() {
              if(this.open) {
                  this.$v.$reset();
                  this.init();
                  this.service = new Service();
              }
          },
          'service.has_taxes': function() {
            if(!this.service.has_taxes) {
                this.taxes = this.taxes.map(x => {return {...x, selected: false}})
            }else {
                this.taxes = this.taxes.map(x => {return {...x, selected: true}})
            }
          }
      }
  
  }
  </script>
  
  <style>
  
  </style>