<template>
    <textarea  :type="type" :value="value" @input="$emit('input', $event.target.value)" class="input-full-fucsia" :rows="rows"  :placeholder="placeholder"></textarea>
</template>

<script>
import typeable from '../../../mixins/typeable';

export default {
    name: 'rb-textarea',
    mixins: [typeable],
    props: {
        value: {
            type: [String, Number, Image],
            default: ""
        },
        placeholder:  {
            type: String,
            default: ""
        },
        rows: {
            type: String,
            default: '5'
        }
    },
    computed: {
        type() {
           let types = {...this.typeableClass}
            return Object.keys(types).find(key => types[key] == true)
        },
    },

}
</script>

<style>

</style>