<template>
  <div class="py-2 bg-gray-100" style="min-height: 80%;">

       <v-loading :is_loading="isloading" />
        <v-card elevation="1" class="mt-2 px-5">
            <v-card-text class="grid gap-y-3">
                <div class="mt-2 px-5 flex justify-between items-center">
                  <label class="font-sans font-bold tracking-wide text-gray-500 text-xl">{{'Lista de Servicios'}}</label>
                <rb-dialog v-model="iscreate" :persist="true">
                    <template #button>
                        <v-btn color="pink" dark fab  small @click.stop="iscreate = true"> <v-icon>mdi-plus</v-icon></v-btn>
                    </template>
                    <template #body>
                        <v-service-create :id_entity="identity" :id_category="id" :open="iscreate" v-on:close="iscreate = false" v-on:success="onsuccess" />
                    </template>
                </rb-dialog>
                </div>
            </v-card-text>
             <v-divider></v-divider>
              <div class="mt-5">
                    <v-data-table
                        item-key="id"
                        :headers="headers"
                        :items="services"
                        class="elevation-1"
                        hide-default-footer
                        :no-data-text="'No se encontraron resultados'"
                        :multi-sort="false"
                    >
                    <template v-slot:[`item.price`]="{item}">
                        {{item.price | currency}}
                    </template>
                    <template v-slot:[`item.active`]="{item}">
                         <v-switch
                            inset
                            v-model="item.active"
                            color="pink lighten-1"
                            @click="onClickActive(item)"
                            ></v-switch>
                    </template>
                    <template v-slot:[`item.actions`]="{item}">
                            <rb-options :actions="actions" v-on:event="(event) => onEvent(event, item)">
                                <template #buttonsheet>
                                    <rb-icon-settings class="block md:hidden" />
                                </template>
                                <template #buttonmenu>
                                    <rb-icon-settings class="hidden md:block" />
                                </template>
                            </rb-options>
                    </template>
                    </v-data-table>
                    <div class="text-center mt-3">
                    <v-pagination
                        color="pink lighten-1"
                        v-model="pagination.page"
                        :length="pagination.total"
                        :total-visible="5"
                        circle
                    ></v-pagination>
                    </div>
                </div>
        </v-card>

        <rb-dialog v-model="isupdate" :persist="true">
            <template #body>
                <rb-update-service :id="id_service" :id_category="id" :id_entity="identity" :open="isupdate" v-on:close="isupdate = false" v-on:success="onsuccessUpdate" />
            </template>
        </rb-dialog>

  </div>
</template>

<script>
import RBDialog from '../../../@core/components/form/modal/RBDialog.vue';
import RBOptionSettings from '../../../@core/components/form/icon/RBOptionSettings.vue';
import UpdateService from './update.vue';
import ToastMixin from '@/mixins/vue/toastmixin';
import globalMixin from '@/mixins/globalMixin';
import { ServicesService } from '../../../services/HSuite/Context/hsuite-product-context';
import actions from '../../../utils/common/actions';
import vservicecreate from './create.vue';


export default {
    mixins: [globalMixin, ToastMixin],
     props: {
        id: {
            type: String,
            default: ""
        },
        identity: {
            type: String,
            default: ""
        }
    },
    components: {
        'v-service-create' : vservicecreate,
        'rb-update-service' : UpdateService,
        'rb-dialog' : RBDialog,
        'rb-icon-settings' : RBOptionSettings
    },
    data: function() {
        return {
             isloading: false,
             iscreate: false,
             isupdate: false,
             services: [],
             id_service: '',
             headers: [
                {text: 'Nombre', value: 'name'},
                {text: 'Descripción', value: 'description'},
                {text: 'Folio', value: 'invoice'},
                {text: 'Precio', value: 'price'},
                {text: '¿Mostrar?', value: 'active'},
                {text: 'Acciones', value: 'actions',}
            ],
            TYPE_SERVICE_STATUS: {HIDE: 1, DELETE: 2}
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            this.isloading = true;
            await this.getServices(this.id, this.identity ,this.pagination.page, this.pagination.perpage);
            this.isloading = false;
        },
        getServices: async function(id_category, id_entity, page, limit) {
            let response = await ServicesService.getByCategory(id_category, id_entity, page, limit);
            if(response.success) {
                this.services = response.data.data;
                 this.pagination.total = response.data.total_page;
            }else {
                this.failure(response.message);
            }
        },
        onEvent: function (event, item) {
             switch(event) {
                case actions.EDIT:
                   this.onUpdate(item.id);
                break;
                case actions.DELETE:
                   this.onDelete(item);
                break;
                case actions.SHOW:
                 this.$router.push(`/entity/service/${item.id}/${this.identity}`)
                break;
            }
        },
        onClickActive: async function (item) {
            let action = item.active;
            let confirm = await this.confirm(action ? 'Mostrar' : 'Ocultar', '¿Desea ocultar/mostrar el servicio en su categoría?');
            if(confirm) {
                let data = {active: item.active, type: this.TYPE_SERVICE_STATUS.HIDE};
                this.isloading = true;
                let response = await ServicesService.status(item.id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                     item.active = action ? false : true;
                }

            }else {
                item.active = action ? false : true;
            }
        },
        onDelete: async function (item) {
            let confirm = await this.confirm('Eliminar', '¿Desea eliminar el servicio en su categoría?');
            if(confirm) {
                let data = {active: false, type: this.TYPE_SERVICE_STATUS.DELETE};
                this.isloading = true;
                let response = await ServicesService.status(item.id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }
            }
        },
        onsuccess: function () {
            this.iscreate = false;
            this.init();
        },
        onPage: async function() {
          this.isloading = true;
            await this.getServices(this.id, this.identity, this.pagination.page, this.pagination.perpage);
          this.isloading = false;
      },
      onUpdate: function(id) {
        this.isupdate = true;
        this.id_service = id;
      },
      onsuccessUpdate: function() {
        this.isupdate = false;
        this.init()
      }
    },
    computed: {
        actions: function(){
             let btnactions =  [
                {label: 'Visualizar', icon: 'eye', action: actions.SHOW, description: 'Visualizar los datos del servicio'},
                {label: 'Actualizar', icon: 'edit', action: actions.EDIT, description: 'Actualiza los datos del servicio'},
                {label: 'Eliminar', icon: 'trash-alt', action: actions.DELETE, description: 'Elimina el servicio'},
            ]
            return btnactions;
         }
    },
     watch: {
        'pagination.page': function() {
            this.onPage();
        },
    }

}
</script>

<style>

</style>