<template>
  <div>
    <div class="px-5 py-1">
        <span class="text-gray-400 font-sans font-semibold tracking-wide">Datos Generales</span>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 border-b border-gray-200">
        <rb-category-hi>
            <template #title>NOMBRE:</template>
            <template #text>{{category.name ? category.name : ''}}</template>
        </rb-category-hi>
        <rb-category-hi>
            <template #title>CÓDIGO:</template>
            <template #text>{{category.code ? category.code : ''}}</template>
        </rb-category-hi>
        <rb-category-hi>
            <template #title>FECHA DE CREACIÓN:</template>
            <template #text>{{category.created_at | formatDate }}</template>
        </rb-category-hi>
    </div>
        <div class="px-5 py-1">
        <span class="text-gray-400 font-sans font-semibold tracking-wide">Productos</span>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 border-b border-gray-200">
        <rb-category-hi>
            <template #title>TOTAL DE PRODUCTOS:</template>
            <template #text>{{category.total_products ? category.total_products : '0'}}</template>
        </rb-category-hi>
        <rb-category-hi>
            <template #title>SUBTOTAL:</template>
            <template #text>{{category.importe | currency}}</template>
        </rb-category-hi>
        <rb-category-hi>
            <template #title>TIPO:</template>
            <template #text>{{category.desc_category_type ? category.desc_category_type : ''}}</template>
        </rb-category-hi>
    </div>
        <div class="px-5 py-1">
        <span class="text-gray-400 font-sans font-semibold tracking-wide">Impuestos</span>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 border-b border-gray-200">
        <rb-category-hi v-for="(tax, i) in taxes" :key="i">
            <template #title>IMPUESTO:</template>
            <template #text>{{tax.code ? `${tax.code}(${tax.value+'%'})` : ''}}</template>
        </rb-category-hi>
    </div>
  </div>
</template>

<script>
import HeaderInfoItem from './RBCategoryHeaderInfoItem.vue'

export default {
    props: {
        category: {
            type: Object,
            default: function() {return {} }
        },
        taxes: {
            type: Array,
            default: function() {return []}
        }
    },
    components: {
        'rb-category-hi': HeaderInfoItem
    }
}
</script>

<style>

</style>