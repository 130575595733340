<template>
  <v-card>
        <v-loading :is_loading="isloading" />
       <v-modal-title v-on:close="$emit('close')">
            Agregar Producto
       </v-modal-title>
       <v-divider></v-divider>
         <v-skeleton v-if="loading" />
        <v-card-text v-if="!loading">
           <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
               <div>
                    <label class="text-base font-sans font-semibold">Nombre</label>
                    <input :disabled="isloading" v-model="$v.product.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                    <small class="text-error" v-show="$v.product.name.$anyDirty && !$v.product.name.required">* El nombre es requerido</small>
                </div>
                <div>
                    <label class="text-base font-sans font-semibold">Precio</label>
                    <input type="number" step="any" :disabled="isloading" v-model.number="$v.product.price.$model" class="input-full-fucsia"  placeholder="Ingrese el precio" />
                    <small class="text-error" v-show="$v.product.price.$anyDirty && !$v.product.price.required">* El precio es requerido</small>
                </div>
           </div>
           <div class="grid grid-cols-1 px-1 py-1 gap-5">
                <div>
                    <label class="text-base font-sans font-semibold">Descripción</label>
                    <textarea :disabled="isloading" v-model="$v.product.description.$model" class="input-full-fucsia" rows="5" placeholder="Ingrese la descripción"></textarea>
                    <div class="flex justify-between">
                        <div>         
                            <small class="text-error" v-show="$v.product.description.$anyDirty && !$v.product.description.required">* La descripción es requerida</small>
                            <small class="text-error" v-show="$v.product.description.$anyDirty && !$v.product.description.minLength">* La descripción debe contener un mínimo de {{$v.product.description.$params.minLength.min}} caracteres</small>
                            <small class="text-error" v-show="$v.product.description.$anyDirty && !$v.product.description.maxLength">* La descripción debe contener un máximo de {{$v.product.description.$params.maxLength.max}} caracteres</small>
                        </div>
                        <div>
                            <small>{{ product.description.length +' / '+$v.product.description.$params.maxLength.max }}</small>
                        </div>
                    </div>
                </div>
           </div>
           <div class="grid grid-cols-1 px-1 py-1 gap-5" v-if="taxes_active && taxes_active.length > 0">
                <div class="flex flex-wrap">
                    <v-sheet class="px-5">
                        <div class="flex items-center gap-5">
                            <v-switch
                            v-model="product.has_taxes"
                            color="success"
                            inset
                            :label="`${'¿Incluye Impuestos?'}`"
                            ></v-switch>
                        </div>
                    </v-sheet>
                </div>
           </div>
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                <div>
                    <v-sheet class="px-5">
                        <div class="flex items-center gap-5">
                            <v-switch
                            v-for="(tax_item, i) in taxes_active"
                            :key="i"
                            v-model="tax_item.selected"
                            color="success"
                            inset
                            :label="`${tax_item.code}`"
                            ></v-switch>
                        </div>
                    </v-sheet>
                </div>
                <div >
                    <div class="flex justify-between items-center border-b border-gray-400">
                      <label class="text-base font-sans font-semibold">Subtotal </label>
                      <label class="text-base font-sans font-normal text-gray-500">{{subtotal | currency}} </label>
                    </div>
                     <div class="flex justify-between items-center border-b border-gray-400" v-for="(tax, i) in taxes_selected" :key="i">
                      <label class="text-base font-sans font-semibold">{{tax.code ? `${tax.code}(${tax.value}%)` : ''}}</label>
                      <label class="text-base font-sans font-normal text-gray-500">{{tax.value_taxe | currency }} </label>
                    </div>
                    <div class="flex justify-between items-center border-b border-gray-400">
                      <label class="text-base font-sans font-semibold">Total </label>
                      <label class="text-base font-sans font-normal text-gray-500">{{total | currency}} </label>
                    </div>
                </div>
             </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <button :disabled="isloading"
                class="bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
                @click="$emit('close')">Cerrar</button>
            <button :disabled="isloading"
                class="bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
             @click="save"  >Guardar</button>
        </v-card-actions>
  </v-card>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import Products from '../../../models/products/products.model';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import entityTaxService from '../../../services/entity/entitytax.service';
import { MenuService } from '../../../services/HSuite/Context/hsuite-product-context';

export default {
     mixins: [validationMixin, toastMixin],
     props: {
        open: {
            type: Boolean,
            default: false
        },
        id_entity: {
            type: String,
            default: ""
        },
        id_category: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            isloading: false,
            loading: false,
            product: new Products(),
            taxes: []
        }
    },
    
    validations: {
         product: {
            name: {
                 required
            },
            description: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(200)
            },
            price: {
                 required
            }
         }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            if(this.id_entity != null && this.id_entity != '') {
                this.loading = true;
                await this.getEntityTaxs(this.id_entity);
                this.loading = false;
            }
        },
        getEntityTaxs: async function(id) {
            let response = await entityTaxService.getAll(id);
            if(response.success) {
                this.taxes = response.data;
                this.taxes = this.taxes.map((item => ({...item, selected: false})));
                let taxes_map = this.taxes.filter(x => x.activo);
                taxes_map.map((x) => x.selected = true);
            }
        },
        save: async function() {
            let taxes_str = this.taxes_selected.map((tax) => {
                return tax.id_tax;
            }).join(',');

            this.product.taxes = taxes_str ? (taxes_str + ',') : '';
            this.product.id_category = this.id_category;
            this.product.id_entity = this.id_entity;
            this.$v.$touch();
            if(!this.$v.$anyError) {
                console.log(this.product);
                this.isloading = true;
                let response = await MenuService.saveProductCategory(this.product);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.$emit('success');
                }else {
                    this.failure(response.message);
                }
            }
        }

    },
    computed: {
        taxes_active: function() {
            let taxes = this.taxes.filter(x => x.activo);
            return taxes ? taxes : []
        },
        taxes_selected: function() {
              let taxes = this.taxes_active.filter(x => x.selected);
              return taxes ? taxes : []
        },
        subtotal: function() {
            let response = this.product.price || 0;
            if(this.product.has_taxes) {
                let taxes_calculated = 0;
                for(let taxe of this.taxes_selected) {
                    taxes_calculated += (taxe.value / 100);
                }
                taxes_calculated += 1;
                for(let taxe of this.taxes_selected) {
                    let value_taxe = (response * (taxe.value / 100)) / (taxes_calculated);
                    taxe.value_taxe = value_taxe;
                }
                for(let taxe of this.taxes_selected) {
                    response = response - taxe.value_taxe
                }
            }else {
                 for(let taxe of this.taxes_selected) {
                    let value_taxe = (taxe.value / 100) * this.product.price;
                    taxe.value_taxe = value_taxe;
                }
            }
            return response;
        },
        total: function() {
            let response = this.subtotal;
            for(let taxe of this.taxes_selected) {
                response += taxe.value_taxe;
            }
            return response;
        }
    },
    watch: {
         open: function() {
            if(this.open) {
                this.$v.$reset();
                this.init();
                this.product = new Products();
            }
        }
    }

}
</script>

<style>

</style>